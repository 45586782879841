import { useState, useEffect } from 'react'

export default function Navbar ({ links }) {
  const navbarAnchorClasses = 'text-2xl font-bold text-primary hover:text-accent drop-shadow'
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)

  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : 'auto'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [menuOpen])

  return (
    <nav className='flex items-center justify-between md:justify-start gap-4'>
      <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt='Logo' className='z-50 drop-shadow hover:animate-pulse' />
      {links.map((link, index) => (<a key={index} href={'#' + link.toLowerCase()} className={navbarAnchorClasses + ' hidden md:flex'}>{link}</a>))}

      <button className='md:hidden z-50 text-primary transition-transform duration-500' onClick={toggleMenu}>
        <svg className={`w-6 h-6 transform transition-transform ${menuOpen ? 'rotate-90' : ''}`} fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d={menuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'} />
        </svg>
      </button>
      <div className={`fixed inset-0 text-primary flex flex-col items-center justify-center gap-4 z-40 backdrop-blur-md transition-transform ${menuOpen ? 'translate-y-0' : '-translate-y-full'}`}>
        {links.map((link, index) => (<a key={index} href={'#' + link.toLowerCase()} className={navbarAnchorClasses} onClick={toggleMenu}>{link}</a>))}
      </div>
    </nav>
  )
}
