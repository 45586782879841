import Navbar from './Navbar'
import Section, { sectionTitleClasses } from './Section'
import ContentSection from './ContentSection'
import TimelineCard from './TimelineCard'
import { Link } from 'react-router-dom'
import Marquee from 'react-fast-marquee'
import ProfileCard from './ProfileCard'

export default function App () {
  const containersClasses = 'container max-w-screen-2xl mx-auto p-4'

  return (
    <div className='bg-gradient-to-r from-violet-500 to-fuchsia-500 break-words'>
      <header className={containersClasses}>
        <Navbar links={['Vision', 'Updates', 'Demo', 'Team']} />
      </header>

      <main className={containersClasses}>
        <Section id='hero'>
          <h1 className={sectionTitleClasses + ' text-6xl md:text-9xl animate-[bounce_1s_ease-out_1.5]'}>
            <span className='text-accent'>
              For Your Formation !
            </span>
          </h1>
        </Section>

        <ContentSection title={['Embrace our', 'Vision']}>
          <p className='text-justify text-secondary'>
            In a rapidly evolving business landscape, training has become more than just a necessity — it's a fascinating challenge. Videolessons you are currently using just aren't cutting it.
            <br />
            At Climb, we believe that the future of corporate learning lies in immersive, dynamic, and interactive experiences that engage employees and foster real growth.
            <br />
            <br />
            Our approach is rooted in the philosophy of <i>learning by doing</i>. We understand that true understanding and skill mastery come from hands-on experience.
            <br />
            That's why we've created a platform where users don't just watch and listen — they actively participate in their learning journey.
            <br />
            <br />
            We believe that learning should be engaging and interactive.
            <br />
            By incorporating game-based learning principles, we create an environment where users are motivated to solve challenges, interact with AI-driven characters, and test their knowledge in real-world simulations.
            <br />
            This approach not only boosts engagement but also ensures that the skills learned are immediately applicable.
            <br />
            <br />
            At Climb, we're not just reimagining training — we're transforming it.
            <br />
            Join us in making learning a captivating and impactful experience for your enterprise.
          </p>
        </ContentSection>

        <ContentSection title={['Explore our', 'Updates']}>
          <div className='relative flex flex-col gap-4'>
            <div className='z-0 absolute flex justify-around w-full h-full'>
              <div className='border border-primary h-full' />
            </div>
            <TimelineCard author='Lorenzo' date='2023-04-07' comment='I got hired!' direction='right' />
            <TimelineCard author='Lorenzo' date='2023-08-12' comment='OMG! We completed our first game design document, what a crazy accomplishment!' direction='left' />
            <TimelineCard author='Lorenzo' date='2024-01-10' comment="I set this thing up. Hope it's appreciated…" direction='right' />
            <TimelineCard author='Mattia' date='2024-08-11' comment="We finally completed our Demo: it was fun trying to integrate the systems and finding the most suitable setting. Having spent every free minute developing, Lorenzo won't be tanned this year either." direction='left' />
            <TimelineCard author='Andrea' date='2024-09-02' comment='Pitch finished! We created a short slide deck which easily explains how Climb works. Do you want to read it? Write to us!' direction='right' />
            <TimelineCard author='Lorenzo' date='2024-09-06' comment="Site up & running. We have worked on it to explain our Vision and make it easy for everyone to try our Demo. But above else, because we like to look at Andrea's photo again and again, HOW BEAUTIFUL IS HE!?" direction='left' />
          </div>
        </ContentSection>

        <ContentSection title={['Try our', 'Demo']}>
          <div className='flex justify-around'>
            <Link to='/demo' target='_blank'>
              <button className='bg-primary hover:bg-accent text-secondary font-bold py-2 px-4 rounded'>
                <div className='flex items-center'>
                  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth='2' className='size-10'>
                    <path strokeLinecap='round' strokeLinejoin='round' d='M8 4l8 8-8 8M16 12H4' />
                  </svg>
                  Avalaible right now!
                </div>
              </button>
            </Link>
          </div>
        </ContentSection>

        <ContentSection title={['Meet our', 'Team']}>
          <Marquee pauseOnHover autoFill>
            <div className='flex flex-row items-stretch'>
              <ProfileCard name='Carlo' title='Lawyer & motorbike explorer' />
              <ProfileCard name='Andrea' title='Product lead & part-time dad' />
              <ProfileCard name='Lorenzo' title='Computer scientist & videogame addict' />
              <ProfileCard name='Mattia' title='Marketing expert & future chess champion' />
            </div>
          </Marquee>
        </ContentSection>
      </main>

      <footer className={containersClasses}>
        <div className='flex flex-col items-center justify-between gap-4'>
          <img src={`${process.env.PUBLIC_URL}/logomark.svg`} alt='Logomark' className='h-64' />
          <div className='text-primary'>© 2023 Climb2learn. All rights reserved.</div>
        </div>
      </footer>
    </div>
  )
}
