import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import Demo from './components/Demo'
import NotFound from './components/NotFound'

export default function App () {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/demo' element={<Demo />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  )
}
