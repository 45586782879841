export default function ProfileCard ({ name, title }) {
  return (
    <div className='w-64 md:w-96 rounded-2xl shadow bg-primary mx-2 overflow-hidden'>
      <img src={`${process.env.PUBLIC_URL}/images/${name}.jpg`} alt={name} className='w-full' />
      <div className='p-4'>
        <div className='text-accent text-ls font-semibold mb-2'>
          {name}
        </div>
        <div className='text-secondary'>
          {title}
        </div>
      </div>
    </div>
  )
}
