export default function TimelineCard ({ author, date, comment, direction }) {
  return (
    <div className={`flex ${direction === 'right' ? 'flex-row' : 'flex-row-reverse'} justify-center items-center w-full`}>
      <div className='w-5/12' />
      <div className='w-2/12 flex justify-around'>
        <div className='z-10 p-2 bg-secondary text-primary text-center rounded-full w-fit'>
          {new Date(date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}
        </div>
      </div>
      <div className={`flex ${direction === 'right' ? 'justify-start' : 'justify-end'} items-center w-5/12`}>
        {direction === 'right' && (<div className='border-y-8 border-y-transparent border-r-8 border-r-current text-secondary' />)}
        <div className='p-4 bg-secondary rounded-2xl shadow'>
          <div className='text-accent text-xl font-bold mb-2'>
            {author}
          </div>
          <div className='text-primary'>
            {comment}
          </div>
        </div>
        {direction === 'left' && (<div className='border-y-8 border-y-transparent border-l-8 border-l-current text-secondary' />)}
      </div>
    </div>
  )
}
