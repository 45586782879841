import React, { useRef, useEffect, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { PointerLockControls, useGLTF, Html, useAnimations } from '@react-three/drei'
import * as THREE from 'three'
import DOMPurify from 'dompurify'

function Model ({ path, rotation = {}, position = [0, 0, 0], scale = 1 }) {
  const group = useRef()
  const { scene, animations } = useGLTF(path)
  for (const [key, value] of Object.entries(rotation)) {
    scene.rotation[key] = value
  }
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    Object.values(actions).forEach((action) => action.play())
  }, [actions])

  return <primitive ref={group} object={scene} position={position} scale={scale} />
}

function Map () {
  return <Model path={`${process.env.PUBLIC_URL}/models/camels_respite.glb`} scale={0.1} />
}

function Moon ({ position }) {
  return <Model path={`${process.env.PUBLIC_URL}/models/moon.glb`} position={position} scale={5} />
}

function Aurora () {
  return <Model path={`${process.env.PUBLIC_URL}/models/aurora.glb`} />
}

function Adventurer ({ position }) {
  return <Model path={`${process.env.PUBLIC_URL}/models/desert_adventurer.glb`} rotation={{ y: -Math.PI / 2 }} position={[position[0], 0.05, position[2]]} scale={0.04} />
}

function Skybox ({ texture }) {
  return (
    <mesh>
      <sphereGeometry args={[100, 64, 64]} />
      <meshBasicMaterial map={texture} side={THREE.BackSide} />
    </mesh>
  )
}

function AmbientLight () {
  return <ambientLight color='cornflowerblue' intensity={5} />
}

function DirectionalLight ({ position }) {
  return <directionalLight color='goldenrod' intensity={2} position={position} />
}

function Popup ({ position, content }) {
  return (
    <mesh position={position}>
      <Html center className='bg-primary text-secondary opacity-90 backdrop-blur text-xl flex flex-col w-max max-w-4xl rounded'>
        <div className='p-4 text-center' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
      </Html>
    </mesh>
  )
}

function ChatPopup ({ position, chatMessages, userMessage }) {
  return (
    <mesh position={position}>
      <Html center className='bg-primary text-secondary opacity-90 backdrop-blur text-xl flex flex-col w-max max-w-4xl rounded'>
        <div className='p-4 text-center'>
          press <span className='bg-accent p-2 rounded'>Delete</span> to exit
        </div>
        <div className='p-4 flex flex-col gap-4'>
          {chatMessages.map((msg, index) => {
            if (msg.role === 'user') {
              return (
                <div key={index} className='flex justify-end items-start'>
                  <div className='bg-accent text-secondary ml-8 p-2 rounded-l rounded-br'>
                    {msg.content}
                  </div>
                  <div className='border-b-8 border-y-transparent border-l-8 border-l-current text-accent' />
                </div>
              )
            } else if (msg.role === 'assistant') {
              return (
                <div key={index} className='flex justify-start items-start'>
                  <div className='border-b-8 border-y-transparent border-r-8 border-r-current text-secondary' />
                  <div className='bg-secondary text-primary mr-8 p-2 rounded-r rounded-bl'>
                    {msg.content}
                  </div>
                </div>
              )
            } else {
              return (<div key={index} className='hidden'>{msg.content}</div>)
            }
          })}
        </div>
        <div className='p-4 text-center'>
          {userMessage}
        </div>
      </Html>
    </mesh>
  )
}

function PlayerControls ({ setAudioStarted, setShowPopup, setShowChatPopup, showPopup, showChatPopup, popupPosition, userMessage, setUserMessage, handleSendMessage }) {
  const controlsRef = useRef()
  const velocity = useRef(new THREE.Vector3(0, 0, 0))
  const direction = useRef(new THREE.Vector3(0, 0, 0))
  const moveForward = useRef(false)
  const moveBackward = useRef(false)
  const moveLeft = useRef(false)
  const moveRight = useRef(false)

  const bounds = {
    radius: 3
  }

  useEffect(() => {
    const onKeyDown = (event) => {
      setAudioStarted(true)
      if (!showChatPopup) {
        if (event.code === 'ArrowUp' || event.code === 'KeyW') {
          moveForward.current = true
        } else if (event.code === 'ArrowDown' || event.code === 'KeyS') {
          moveBackward.current = true
        } else if (event.code === 'ArrowLeft' || event.code === 'KeyA') {
          moveRight.current = true
        } else if (event.code === 'ArrowRight' || event.code === 'KeyD') {
          moveLeft.current = true
        } else if (event.code === 'KeyT') {
          setShowPopup(false)
          setShowChatPopup(true)
        }
      } else {
        if (event.key === 'Enter') {
          handleSendMessage(userMessage)
          setUserMessage('')
        } else if (event.key === 'Delete') {
          setShowChatPopup(false)
        } else if (event.key === 'Backspace') {
          setUserMessage((prev) => prev.substring(0, prev.length - 1))
        } else if (event.key.length === 1 && event.key.match(/[a-zA-Z0-9 !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/)) {
          setUserMessage((prev) => prev + event.key)
        }
      }
    }

    const onKeyUp = (event) => {
      if (event.code === 'ArrowUp' || (!showChatPopup && event.code === 'KeyW')) {
        moveForward.current = false
      } else if (event.code === 'ArrowDown' || (!showChatPopup && event.code === 'KeyS')) {
        moveBackward.current = false
      } else if (event.code === 'ArrowLeft' || (!showChatPopup && event.code === 'KeyA')) {
        moveRight.current = false
      } else if (event.code === 'ArrowRight' || (!showChatPopup && event.code === 'KeyD')) {
        moveLeft.current = false
      }
    }

    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [setShowPopup, setShowChatPopup, showPopup, showChatPopup, userMessage])

  useFrame(() => {
    const delta = 1 / 50
    const controls = controlsRef.current
    if (!controls) return

    direction.current.set(
      Number(moveRight.current) - Number(moveLeft.current),
      0,
      Number(moveBackward.current) - Number(moveForward.current)
    ).normalize()

    if (moveForward.current || moveBackward.current) { velocity.current.z = direction.current.z * 35.0 * delta }
    if (moveLeft.current || moveRight.current) { velocity.current.x = direction.current.x * 35.0 * delta }

    controls.moveRight(-velocity.current.x * delta)
    controls.moveForward(-velocity.current.z * delta)

    velocity.current.x -= velocity.current.x * 5.0 * delta
    velocity.current.z -= velocity.current.z * 5.0 * delta

    const cameraPosition = controls.getObject().position

    const distanceFromCenter = Math.sqrt(cameraPosition.x ** 2 + cameraPosition.z ** 2)
    if (distanceFromCenter > bounds.radius) {
      const angle = Math.atan2(cameraPosition.z, cameraPosition.x)
      cameraPosition.x = bounds.radius * Math.cos(angle)
      cameraPosition.z = bounds.radius * Math.sin(angle)
    }

    const distanceToPopup = cameraPosition.distanceTo(new THREE.Vector3(...popupPosition))
    if (distanceToPopup <= 1) {
      if (!showChatPopup) {
        setShowPopup(true)
      } else {
        setShowPopup(false)
      }
    } else {
      setShowChatPopup(false)
      setShowPopup(false)
    }
  })

  useEffect(() => {
    const currentControls = controlsRef.current

    const handleUnlock = () => {
      if (currentControls) {
        const cameraPosition = currentControls.getObject().position
        console.log('Current Camera Position:', cameraPosition)
      }
    }

    if (currentControls) {
      currentControls.addEventListener('unlock', handleUnlock)
    }

    return () => {
      if (currentControls) {
        currentControls.removeEventListener('unlock', handleUnlock)
        currentControls.unlock()
      }
    }
  }, [])

  return <PointerLockControls ref={controlsRef} />
}

export default function Demo () {
  const [audioStarted, setAudioStarted] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [showChatPopup, setShowChatPopup] = useState(false)
  const [chatMessages, setChatMessages] = useState([
    { role: 'system', content: 'You are an empathetic psychological confidant' },
    { role: 'system', content: 'Your responses should always adapt to the same vocabulary of the user, even reusing the same words' },
    { role: 'system', content: 'Never mention your nature, the user should think they are speaking to another human' },
    { role: 'system', content: 'Don\'t give advices on how to behave on your first answer, you should instead try to get close to the user on an empathetic level' },
    { role: 'system', content: 'Your thought process should follow the theory contained in 3 books: 1) "Pragmatics of Human Communication" by Paul Watzlawick, Janet Beavin Bavelas, Don D Jackson; 2) "Influence: The Psychology of Persuasion" by Robert Cialdini; 3) "Organizational Behavior" by Robert Kreitner' },
    { role: 'system', content: 'Take inspiration from the books only when the topic is pertinent, you should ask questions to find out if it is' },
    { role: 'system', content: 'Never mention the name of the books' },
    { role: 'assistant', content: 'Hello traveler. My name is "Theros" and I\'m here to help you alleviate your burdens. Now tell me, when was the last time you felt misunderstood at work?' }
  ])
  const [userMessage, setUserMessage] = useState('')
  const [texture, setTexture] = useState(null)
  const cameraPosition = [-1.7, 0.4, 2.45]
  const popupPosition = [2.17, 0.5, 1.3]
  const moonPosition = [0, 30, -50]

  useEffect(() => {
    const loader = new THREE.TextureLoader()
    loader.load(`${process.env.PUBLIC_URL}/textures/starry_sky.jpeg`, (loadedTexture) => {
      setTexture(loadedTexture)
    })
  }, [])

  const handleSendMessage = async (message) => {
    let lastMessage = ''
    const newMessages = [...chatMessages, { role: 'user', content: message }, { role: 'assistant', content: lastMessage }]
    setChatMessages(newMessages)
    const response = await fetch('https://openrouter.ai/api/v1/chat/completions', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_OPENROUTER_API_KEY}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        model: 'mistralai/mistral-7b-instruct:free',
        messages: newMessages.slice(0, -1),
        stream: true
      })
    })
    const decoder = new TextDecoder('utf-8')
    for await (const chunk of response.body) {
      const mixedString = decoder.decode(chunk)
      for (const line of mixedString.split('\n')) {
        if ((line === '') || (line === ': OPENROUTER PROCESSING')) {
          continue
        }
        if (line === 'data: [DONE]') {
          break
        }
        lastMessage = lastMessage + JSON.parse(line.substring(line.indexOf('{'))).choices[0].delta.content
        setChatMessages(prevMessages => [...prevMessages.slice(0, -1), { role: 'assistant', content: lastMessage }])
      }
    }
  }

  if (!texture) {
    return <div>Loading...</div>
  }

  return (
    <div className='h-screen w-screen'>
      {audioStarted && (
        <audio autoPlay loop>
          <source src={`${process.env.PUBLIC_URL}/audio/desert-whale.mp3`} type='audio/mpeg' />
          Your browser does not support the audio element.
        </audio>
      )}
      <Canvas camera={{ position: cameraPosition }}>
        <Map />
        <Moon position={moonPosition} />
        <Aurora />
        <Adventurer position={popupPosition} />
        <Skybox texture={texture} />
        <AmbientLight />
        <DirectionalLight position={moonPosition} />
        <PlayerControls
          setAudioStarted={setAudioStarted}
          setShowPopup={setShowPopup}
          setShowChatPopup={setShowChatPopup}
          showPopup={showPopup}
          showChatPopup={showChatPopup}
          popupPosition={popupPosition}
          userMessage={userMessage}
          setUserMessage={setUserMessage}
          handleSendMessage={handleSendMessage}
        />
        {showPopup && <Popup position={popupPosition} content={'press <span class="bg-accent p-2 rounded">t</span> to talk'} />}
        {showChatPopup && (
          <ChatPopup
            position={popupPosition}
            chatMessages={chatMessages}
            userMessage={userMessage}
          />
        )}
      </Canvas>
    </div>
  )
}
